import React, { useState } from 'react'
import {
  Button,
  Typography,
  Box
} from '@mui/material'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm'
import ThreatSource from 'src/components/Risk/Forms/ThreatSource' // Import your ThreatSourceForm
import ThreatSourceChar from 'src/components/Risk/Forms/ThreatSourceChar'
import ThreatEvent from 'src/components/Risk/Forms/ThreatEvent'
import ThreatEventSources from './Forms/ThreatEventSources'
import ThreatEventRelevance from './Forms/ThreatEventRelevance'
import Vulnerability from './Forms/Vulnerability'
import Predisposing from './Forms/Predisposing'
import VulnerabilityThreatEvents from './Forms/VulnerabilityThreatEvents'
import PredisposingThreatEvents from './Forms/PredisposingThreatEvents'
import ThreatLikelihood from './Forms/ThreatLikelihood'
import Impact from './Forms/Impact'
import ImpactThreatEvents from './Forms/ImpactThreatEvent'
import Risk from './Forms/Risk'
import ImpactLevel from './Forms/ImpactLevel'
import RiskCommunication
  from './Forms/RiskCommunication'
import GenericForm
  from './Forms/GenericForm'
import ProcessNavigation from '../General/ProcessNavigation'

const formComponents = {
  default: MultiStepResourceForm,
  associate_threat_sources: ThreatSource,
  get_threat_source_instances: ThreatSourceChar,
  identify_threat_events: ThreatEvent,
  associate_threat_events_to_sources: ThreatEventSources,
  evaluate_threat_events_relevance: ThreatEventRelevance,
  identify_vulnerabilities: Vulnerability,
  identify_predisposing: Predisposing,
  associate_vulnerabilities_to_threat_events: VulnerabilityThreatEvents,
  associate_predisposing_to_threat_events: PredisposingThreatEvents,
  determine_likelihood: ThreatLikelihood,
  identify_impact: Impact,
  determine_impact: ImpactThreatEvents,
  assess_impact: ImpactLevel,
  determine_risk: Risk,
  communicate_risk: RiskCommunication,
  genericform: GenericForm
  // Add more key-value pairs here for other resource names and their corresponding form components
}

const ResourceForm = ({ model, resourceName, instance_id, actions, organization }) => {
  if (resourceName === null) { return null }
  const SpecificForm = formComponents[resourceName] || formComponents.default
  return <SpecificForm model={model} resourceName={resourceName} instance_id={instance_id} actions={actions} organization={organization}/>
}

const MultiProcess = ({ instance_id,organization, data }) => {
  const [selectedItem, setSelectedItem] = useState(null)
  const [completedSteps, setCompletedSteps] = useState({})
  const [completedTasks, setCompletedTasks] = useState({})

  const isDependencyMet = (dependencies, lookup) => {
    for (const dep of dependencies) {
      if (!lookup[dep]) return false
    }
    return true
  }

  const areAllTasksCompleted = (step) => {
    for (const task of step.tasks) {
      if (!completedTasks[task.title]) return false
    }
    return true
  }

  const handleItemSelect = (itemType, item) => {
    setSelectedItem({ type: itemType, data: item })
  }

  const handleStepComplete = (step) => {
    if (areAllTasksCompleted(step)) {
      setCompletedSteps(prev => ({ ...prev, [step.title]: true }))
    }
  }

  const handleTaskComplete = (task) => {
    setCompletedTasks(prev => ({ ...prev, [task.title]: true }))
  }

  if (!data || !data.steps) return null

  return (
    <Box display="flex" >
      {/* Process Navigation Side Menu */}

      <ProcessNavigation steps={data.steps} onItemSelect={handleItemSelect} />

      {/* Main Content Area */}
      <Box flex={1} p={3} width='100px'>
        <Typography variant="h4">
          {selectedItem?.data.title}
        </Typography>
        <Typography variant="body1" paragraph>
          {selectedItem?.data.description}
        </Typography>

        {selectedItem && selectedItem?.data.api_resource && (
          <ResourceForm model={data?.model} resourceName={selectedItem?.data.api_resource} instance_id={instance_id} actions={selectedItem?.data.actions} organization={organization}/>

        )}

        {selectedItem?.type === 'task' && (
          <Button
            variant="contained"
            onClick={() => handleTaskComplete(selectedItem.data)}
            disabled={!isDependencyMet(selectedItem.data.dependencies, completedTasks)}
          >
            Complete Task
          </Button>
        )}

        {selectedItem?.type === 'step' && (
          <Button
            variant="contained"
            onClick={() => handleStepComplete(selectedItem.data)}
            disabled={!areAllTasksCompleted(selectedItem.data) || !isDependencyMet(selectedItem.data.dependencies, completedSteps)}
          >
            Complete Step
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default MultiProcess
