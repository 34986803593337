import React, { useEffect, useState, useMemo } from 'react';
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  Tooltip,
  Chip,
  useTheme,
  ListItemIcon,
  Drawer,
  IconButton,
  CircularProgress,
  Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  MaterialReactTable,
} from 'material-react-table';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import CategorizationComponent from 'src/components/Asset/CategorizationComponent';
import DynamicLevelSelector from 'src/components/General/DynamicLevelSelector';
import BugReportIcon from '@mui/icons-material/BugReport';
import SecurityIcon from '@mui/icons-material/Security';
import LockIcon from '@mui/icons-material/Lock';
import GavelIcon from '@mui/icons-material/Gavel';
import WarningIcon from '@mui/icons-material/Warning';
import ComplianceTable from '../complianceAssessment/ComplianceTable';

const truncateText = (text, maxLength) => {
  if (!text) {
    return '';
  }
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
};

const getNatureIcon = (nature) => {
  switch (nature?.toLowerCase()) {
    case 'software vulnerability':
      return <BugReportIcon />;
    case 'license':
      return <GavelIcon />;
    case 'secret':
      return <LockIcon />;
    case 'misconfiguration':
      return <WarningIcon />;
    case 'noncompliance':
      return <SecurityIcon />;
    default:
      return null;
  }
};

const Overview = ({ assetDetails, catInstance, onEditLevels }) => (
  <>
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <List>
            <ListItem>
              <ListItemText primary="Created By" secondary={assetDetails.created_by} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Created At" secondary={new Date(assetDetails.created_at).toLocaleString()} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Updated At" secondary={new Date(assetDetails.updated_at).toLocaleString()} />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} md={6}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Roles and Responsibilities
          </Typography>
          {assetDetails.roles && Object.keys(assetDetails.roles).length > 0 ? (
            <List>
              {Object.entries(assetDetails.roles).map(([roleTitle, userDetails], index) => (
                <ListItem key={index}>
                  <ListItemText primary={roleTitle} secondary={`${userDetails?.username} (${userDetails?.email})`} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>No roles assigned.</Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
    {catInstance && (
      <Grid item xs={12} md={6}>
        <CategorizationComponent key={catInstance.id} data={catInstance} editLevels={onEditLevels} />
      </Grid>
    )}
  </>
);

const Vulnerabilities = ({ vulns, onRowClick }) => {
  const theme = useTheme();

  const statusColors = {
    open: theme.palette.error.main,
    closed: theme.palette.success.main,
    ignored: theme.palette.warning.main,
  };

  const severityColors = {
    critical: theme.palette.error.main,
    high: theme.palette.warning.main,
    medium: theme.palette.riskLevels.medium,
    low: theme.palette.riskLevels.low,
    info: theme.palette.info.main,
  };

  const circleStyle = {
    borderRadius: '50%', // Makes it a circle
    width: '16px', // Set width and height as needed
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'title',
      header: 'Title',
      size: 200, // Use the 'size' prop to set fixed column width
      Cell: ({ cell, row }) => (
        <Tooltip title={cell.getValue()} arrow>
          <Link
            component="button"
            variant="body2"
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => onRowClick(row.original)}
          >
            <ListItemIcon sx={{ minWidth: '24px', color: severityColors[row.original.severity?.toLowerCase()], marginRight: '8px' }}>
              {getNatureIcon(row.original.nature)}
            </ListItemIcon>
            {truncateText(cell.getValue(), 20)}
          </Link>
        </Tooltip>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      size: 300, // Use the 'size' prop to set fixed column width
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} arrow>
          <span>{truncateText(cell.getValue(), 50)}</span>
        </Tooltip>
      ),
    },
    { accessorKey: 'nature', header: 'Nature', size: 150 }, // Use the 'size' prop to set fixed column width
    {
      accessorKey: 'severity',
      header: 'Severity',
      size: 100, // Use the 'size' prop to set fixed column width
      Cell: ({ cell }) => {
        const value = cell.getValue() ? cell.getValue().toLowerCase() : '';
        const color = severityColors[value] || theme.palette.grey[500];
        return (
          <Chip
            icon={<div style={{ ...circleStyle, backgroundColor: color }} />}
            label={cell.getValue()}
          />
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 100, // Use the 'size' prop to set fixed column width
      Cell: ({ cell }) => {
        const value = cell.getValue() ? cell.getValue().toLowerCase() : '';
        const color = statusColors[value] || theme.palette.grey[500];
        return (
          <Chip
            icon={<div style={{ ...circleStyle, backgroundColor: color }} />}
            label={cell.getValue()}
          />
        );
      },
    }
  ], [severityColors, statusColors, theme.palette.grey, circleStyle]);

  return (
    <Box sx={{ p: 2, width: '100%' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>Vulnerabilities</Typography>
      <MaterialReactTable
        columns={columns}
        data={vulns}
        enableColumnOrdering
        enableStickyHeader
        enableGrouping
      />
    </Box>
  );
};

const Compliance = ({ data, loading }) => (
  <Box sx={{ p: 2, width: '100%' }}>
  <Typography variant="h5" sx={{ marginBottom: '16px' }}>Compliance</Typography>
    {loading ? (
      <CircularProgress />
    ) : (
      <ComplianceTable data={data || []} loading={loading} />
    )}
  </Box>
);


const Risks = ({ risks }) => {
  const theme = useTheme();

  const statusColors = {
    open: theme.palette.error.main,
    closed: theme.palette.success.main,
    ignored: theme.palette.warning.main,
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'threat_source',
      header: 'Threat Source',
      size: 200,
    },
    {
      accessorKey: 'threat_source_type',
      header: 'Threat Source Type',
      size: 150,
    },
    {
      accessorKey: 'objective',
      header: 'Objective',
      size: 200,
    },
    {
      accessorKey: 'associated_feared_event',
      header: 'Associated Feared Event',
      size: 200,
    },
    {
      accessorKey: 'attack_scenario',
      header: 'Attack Scenario',
      size: 300,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 100,
      Cell: ({ cell }) => {
        const value = cell.getValue() ? cell.getValue().toLowerCase() : '';
        const color = statusColors[value] || theme.palette.grey[500];
        return (
          <Chip
            label={cell.getValue()}
            sx={{
              backgroundColor: color,
              color: theme.palette.getContrastText(color),
            }}
          />
        );
      },
    },
    {
      accessorKey: 'risk.Risk',
      header: 'Risk',
      size: 150,
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue() || 'Not Specified'} arrow>
          <span>{cell.getValue() || 'Not Specified'}</span>
        </Tooltip>
      ),
    },
  ], [statusColors, theme.palette.grey]);

  return (
    <Box sx={{ p: 2, width: '100%' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>Risks</Typography>
      <MaterialReactTable
        columns={columns}
        data={risks}
        enableColumnOrdering
        enableStickyHeader
        muiTableContainerProps={{
          sx: { maxHeight: '1000px' },
        }}
      />
    </Box>
  );
};


const VulnerabilityDetails = ({ vulnerability, onClose }) => {
  const theme = useTheme();
  const statusColors = {
    open: theme.palette.error.main,
    closed: theme.palette.success.main,
    ignored: theme.palette.warning.main,
  };

  const severityColors = {
    critical: theme.palette.error.main,
    high: theme.palette.warning.main,
    medium: theme.palette.riskLevels.medium,
    low: theme.palette.riskLevels.low,
    info: theme.palette.info.main,
    unknown: theme.palette.grey[500],
  };

  const { getCompliance: getSoftwareVuln, compliance: softwareVuln } = useRequestCompliance({ endpoint: 'software_vulnerabilities' });
  const { getCompliance: getLicense, compliance: license, error: licenseError } = useRequestCompliance({ endpoint: 'licenses' });
  const { getCompliance: getSecret, compliance: secret } = useRequestCompliance({ endpoint: 'secrets' });
  const { getCompliance: getMisconfig, compliance: misconfig } = useRequestCompliance({ endpoint: 'misconfigurations' });


  useEffect(() => {
    if (vulnerability) {
      switch (vulnerability.nature?.toLowerCase()) {
        case 'software vulnerability':
          getSoftwareVuln(vulnerability.software_vulnerability);
          break;
        case 'license':
          getLicense(vulnerability.license);
          break;
        case 'secret':
          getSecret(vulnerability.secret);
          break;
        case 'misconfiguration':
          getMisconfig(vulnerability.misconfiguration);
          break;
        default:
          break;
      }
    }
  }, [vulnerability, getSoftwareVuln, getLicense, getSecret, getMisconfig]);

  const renderDetails = () => {
    if (!vulnerability) {
      return null;
    }
    switch (vulnerability.nature?.toLowerCase()) {
      case 'software vulnerability':
        return softwareVuln ? (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Vulnerability ID:</Typography>
                <Typography variant="body1">{softwareVuln.vulnerability_id}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Package Name:</Typography>
                <Typography variant="body1">{softwareVuln.pkg_name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>PURL:</Typography>
                <Typography variant="body1">{softwareVuln.purl}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>UID:</Typography>
                <Typography variant="body1">{softwareVuln.uid}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Installed Version:</Typography>
                <Typography variant="body1">{softwareVuln.installed_version}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Fixed Version:</Typography>
                <Typography variant="body1">{softwareVuln.fixed_version}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Status:</Typography>
                <Typography variant="body1">{softwareVuln.status}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Severity Source:</Typography>
                <Typography variant="body1">{softwareVuln.severity_source}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Primary URL:</Typography>
                <Link href={softwareVuln.primary_url} target="_blank" rel="noopener">{softwareVuln.primary_url}</Link>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Published Date:</Typography>
                <Typography variant="body1">{new Date(softwareVuln.published_date).toLocaleString()}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Last Modified Date:</Typography>
                <Typography variant="body1">{new Date(softwareVuln.last_modified_date).toLocaleString()}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>CWE IDs:</Typography>
                <Typography variant="body1">{softwareVuln.cwe_ids.join(', ')}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>References:</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {softwareVuln.references.map((ref, index) => (
                    <Link href={ref} key={index} target="_blank" rel="noopener" sx={{ wordBreak: 'break-word' }}>
                      {ref}
                    </Link>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : <CircularProgress />;
      case 'license':
        return license ? (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Package Name:</Typography>
                <Typography variant="body1">{license.pkg_name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>License Name:</Typography>
                <Typography variant="body1">{license.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>File Path:</Typography>
                <Typography variant="body1">{license.file_path}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Confidence:</Typography>
                <Typography variant="body1">{license.confidence}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Link:</Typography>
                <Link href={license.link} target="_blank" rel="noopener">{license.link}</Link>
              </Grid>
            </Grid>
          </Box>
        ) : licenseError ? (
          <Typography variant="body1" color="error">Error loading license details: {licenseError.message}</Typography>
        ) : (
          <CircularProgress />
        );
      case 'secret':
        return secret ? (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Rule ID:</Typography>
                <Typography variant="body1">{secret.rule_id}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Category:</Typography>
                <Typography variant="body1">{secret.category}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Start Line:</Typography>
                <Typography variant="body1">{secret.start_line}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>End Line:</Typography>
                <Typography variant="body1">{secret.end_line}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Match:</Typography>
                <Typography variant="body1">{secret.match}</Typography>
              </Grid>
            </Grid>
          </Box>
        ) : <CircularProgress />;
      case 'misconfiguration':
        return misconfig ? (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Type:</Typography>
                <Typography variant="body1">{misconfig.type}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>ID:</Typography>
                <Typography variant="body1">{misconfig.misconfig_id}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Namespace:</Typography>
                <Typography variant="body1">{misconfig.namespace}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Query:</Typography>
                <Typography variant="body1">{misconfig.query}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Resolution:</Typography>
                <Typography variant="body1">{misconfig.resolution}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Primary URL:</Typography>
                <Link href={misconfig.primary_url} target="_blank" rel="noopener">{misconfig.primary_url}</Link>
              </Grid>
            </Grid>
          </Box>
        ) : <CircularProgress />;
      default:
        return null;
    }
  };

  return (
    <Drawer
      anchor="right"
      open={Boolean(vulnerability)}
      onClose={onClose}
      sx={{
        display: { xs: 'block', sm: 'block', md: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: '60%',
          marginTop: "64px",
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5">Vulnerability Details</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>Title</Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>{vulnerability?.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>Description</Typography>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', mb: 2 }}>{vulnerability?.description}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>Nature</Typography>
              <Typography variant="body1">{vulnerability?.nature}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>Severity</Typography>
              <Chip
                label={vulnerability?.severity}
                sx={{
                  backgroundColor: vulnerability?.severity ? severityColors[vulnerability.severity.toLowerCase()] : theme.palette.grey[500],
                  color: theme.palette.getContrastText(vulnerability?.severity ? severityColors[vulnerability.severity.toLowerCase()] : theme.palette.grey[500]),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>Status</Typography>
              <Chip
                label={vulnerability?.status}
                sx={{
                  backgroundColor: vulnerability?.status ? statusColors[vulnerability.status.toLowerCase()] : theme.palette.grey[500],
                  color: theme.palette.getContrastText(vulnerability?.status ? statusColors[vulnerability.status.toLowerCase()] : theme.palette.grey[500]),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {vulnerability && renderDetails()}
      </Box>
    </Drawer>
  );
};

const DetailedAsset = ({ id, type }) => {
  const [catId, setCatId] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedVulnerability, setSelectedVulnerability] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false); // State to handle the drawer for editing levels

  const { getCompliance, compliance: assetDetails } = useRequestCompliance({ endpoint: type });
  const { getCompliance: getCatInstance, compliance: catInstance, resetCompliance: resetCatInstance } = useRequestCompliance({ endpoint: 'categorization_instances' });
  const { getComplianceList: getVulns, complianceList: vulns } = useRequestCompliance({ endpoint: 'vulnerabilityinstances' });
  const { getComplianceList: getRisks, complianceList: risks } = useRequestCompliance({ endpoint: 'risk_instances' }); // Fetch risks
  const { getComplianceList: getInstances, complianceList: scInstances, loading: loadingInstance } = useRequestCompliance({
    endpoint: 'subcontrolinstancemetric',
    resourceLabel: 'Subcontrol Instance metrics'
  });

  useEffect(() => {
    if (id) {
      getCompliance(id);
      if (type === 'information_systems') {
        getVulns({ information_system: id });
        getRisks({ information_systems: id });  // Fetch risks
        getInstances({ information_systems: id });
      } else if (type === 'supporting_assets') {
        getVulns({ supporting_asset: id });
        getRisks({ supporting_assets: id });  // Fetch risks
        getInstances({ supporting_assets: id });
      } else if (type === 'primary_assets') {
        getVulns({ primary_asset: id });
        getRisks({ primary_assets: id });  // Fetch risks
        getInstances({ primary_assets: id });
      }
      else if (type === 'organizations') {
        getVulns({ organization: id });
        getRisks({ organization: id });  // Fetch risks
        getInstances({ organization: id });
      }
      else if (type === 'missions') {
        getVulns({ mission: id });
        getRisks({ missions: id });  // Fetch risks
        getInstances({ missions: id });
      }
    }
  }, [id, getCompliance, getVulns, getRisks, type]);

  useEffect(() => {
    if (assetDetails) {
      if (assetDetails.security_requirements) {
        setCatId(assetDetails.security_requirements);
      } else if (assetDetails.security_compatibilities) {
        setCatId(assetDetails.security_compatibilities);
      } else {
        setCatId(null); // Explicitly set to null if none of the conditions match
      }
    } else {
      setCatId(null); // Handle case where assetDetails might be null or undefined
    }
  }, [assetDetails]);
  
  useEffect(() => {
    if (catId) {
      getCatInstance(catId);
    }
   else {
    resetCatInstance(); // Explicitly reset catInstance to null when catId is null
  }
  }, [catId, getCatInstance]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleRowClick = (vulnerability) => {
    setSelectedVulnerability(vulnerability);
  };

  const handleDrawerClose = () => {
    setSelectedVulnerability(null);
  };

  const handleEditLevels = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  return (
<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%', // Ensure full width of the screen
  }}
>
  <Box sx={{ width: '100%' }}>
    <Typography variant="h5">{assetDetails?.title || 'Asset Detail'}</Typography>
    <Divider />
    <Typography variant="subtitle1" paragraph>
      {assetDetails?.description}
    </Typography>
  </Box>
  <Box sx={{ display: 'flex', flexGrow: 1, width: '100%' }}>
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={selectedTab}
      onChange={handleTabChange}
      aria-label="Asset Details Tabs"
      sx={{
        borderRight: 1,
        borderColor: 'divider',
        minWidth: '150px',
        maxWidth: '200px',
        flexShrink: 0,
        height: '100%', // Ensure it stretches to fill height
      }}
    >
      <Tab label="Overview" />
      <Tab label="Vulnerabilities" />
      <Tab label="Compliance" />
      <Tab label="Risks" />
    </Tabs>
    <Box
      sx={{
        flexGrow: 1,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        width: '100%', // Ensure the box takes the full available width
        overflow: 'auto', // Allow scrolling if content overflows
      }}
    >
      <Grid container spacing={2} >
        {selectedTab === 0 && assetDetails && (
          <Overview
            assetDetails={assetDetails}
            catInstance={catInstance}
            onEditLevels={handleEditLevels}
          />
        )}
        {selectedTab === 1 && vulns && (
          <Vulnerabilities vulns={vulns.results} onRowClick={handleRowClick} />
        )}
        {selectedTab === 2 && (
          <Compliance data={scInstances?.results?.flat()} loading={loadingInstance} />
        )}
        {selectedTab === 3 && risks && <Risks risks={risks.results} />}
      </Grid>
    </Box>
  </Box>
  <VulnerabilityDetails
    vulnerability={selectedVulnerability}
    onClose={handleDrawerClose}
  />
  <Drawer
    anchor="right"
    open={drawerOpen}
    onClose={closeDrawer}
    sx={{
      display: { xs: 'block', sm: 'block', md: 'block' },
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: '40%',
        marginTop: '64px',
      },
    }}
  >
    <Box p={2} sx={{ flexGrow: 1 }}>
      {catInstance && (
        <DynamicLevelSelector
          data={catInstance.categorization_model}
          initialValues={catInstance}
        />
      )}
    </Box>
  </Drawer>
</Box>

  );
};

export default DetailedAsset;
