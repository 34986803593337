import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import * as echarts from 'echarts/core';
import { GraphChart } from 'echarts/charts';
import { TooltipComponent, LegendComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import ReactECharts from 'echarts-for-react';
import Select from 'react-select';
import { Box, Avatar } from '@mui/material';

// Register the required ECharts components
echarts.use([GraphChart, TooltipComponent, LegendComponent, TitleComponent, CanvasRenderer]);

const StandardsGraph = ({ standards, controls, subControls }) => {
  const theme = useTheme();
  const [option, setOption] = useState({});
  const [selectedStandards, setSelectedStandards] = useState(standards.map(s => s.id)); // Initially select all standards

  // Function to truncate long titles
  const truncateTitle = (title, length = 20) => {
    return title.length > length ? title.slice(0, length) + '...' : title;
  };

  useEffect(() => {
    if (!standards || !controls || !subControls) {
      return;
    }

    const filteredStandards = standards.filter(s => selectedStandards.includes(s.id));

    // Get the ids of the controls that are linked to the selected standards
    const linkedControlIds = new Set(filteredStandards.flatMap(s => s.controls));

    // Filter controls based on the linked control ids
    const filteredControls = controls.filter(c => linkedControlIds.has(c.id));

    // Get the ids of the subcontrols that are linked to the filtered controls
    const linkedSubControlIds = new Set(filteredControls.flatMap(c => c.subControls));

    // Filter subcontrols based on the linked subcontrol ids
    const filteredSubControls = subControls.filter(sc => linkedSubControlIds.has(sc.id));

    // Prepare nodes and links for the graph
    const nodes = [
      ...filteredStandards.map(s => ({
        id: `standard_${s.id}`,
        name: truncateTitle(s.title),
        fullName: s.title,
        description: s.description,
        category: 0,
        symbolSize: 50
      })),
      ...filteredControls.map(c => ({
        id: `control_${c.id}`,
        name: truncateTitle(c.title),
        fullName: c.title,
        description: c.description,
        category: 1,
        symbolSize: 40
      })),
      ...filteredSubControls.map(sc => ({
        id: `subControl_${sc.id}`,
        name: truncateTitle(sc.title),
        fullName: sc.title,
        description: sc.description,
        category: 2,
        symbolSize: 30
      })),
    ];

    const links = [
      ...filteredStandards.flatMap(s => s.controls.map(cId => ({
        source: `standard_${s.id}`,
        target: `control_${cId}`
      }))),
      ...filteredControls.flatMap(c => c.subControls.map(scId => ({
        source: `control_${c.id}`,
        target: `subControl_${scId}`
      }))),
    ];

    // Configure the chart options
    setOption({
      title: {
        text: 'Standards, Controls, and SubControls Mapping',
        top: 'bottom',
        left: 'right',
      },
      tooltip: {
        formatter: function (params) {
          if (params.dataType === 'node') {
            return `<div style="max-width: 300px; white-space: normal;"><b>${params.data.fullName}</b><br>${params.data.description}</div>`;
          }
          if (params.dataType === 'edge') {
            return `${params.data.source} > ${params.data.target}`;
          }
        },
        extraCssText: 'max-width: 300px; white-space: normal;' // Ensure the tooltip does not exceed the div
      },
      legend: {
        data: ['Standard', 'Control', 'SubControl'],
        left: 'left',
      },
      animationDuration: 1500,
      animationEasingUpdate: 'quinticInOut',
      series: [{
        type: 'graph',
        layout: 'force',
        data: nodes,
        links: links,
        categories: [
          { name: 'Standard' },
          { name: 'Control' },
          { name: 'SubControl' }
        ],
        roam: true,
        label: {
          position: 'right',
          formatter: '{b}',
          show: true,
        },
        lineStyle: {
          color: 'source',
          curveness: 0.3,
        },
        emphasis: {
          focus: 'adjacency',
          lineStyle: {
            width: 10
          }
        },
        force: {
          repulsion: 1000,
          edgeLength: [100, 200],
        },
        edgeSymbol: ['circle', 'arrow'],
        edgeSymbolSize: [4, 10],
        focusNodeAdjacency: true, // Highlight adjacent nodes and edges on hover
      }]
    });
  }, [standards, controls, subControls, selectedStandards, theme]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedStandards(selectedOptions.map(option => option.value));
  };

  const selectOptions = standards.map(item => ({
    value: item.id,
    label: item.title,
    image: item.image_url
  }));

  const formatOptionLabel = ({ label, image }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {image ? (
        <img src={image} alt={label} style={{ width: 24, height: 24, marginRight: 2 }} />
      ) : (
        <Avatar sx={{ width: 24, height: 24, marginRight: 2 }}>{label.charAt(0)}</Avatar>
      )}
      {label}
    </div>
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
  };

  if (!standards || !controls || !subControls) {
    return <div>No data available</div>;
  }

  return (
    <div style={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mb: 2, width: '100%' }}>
        <Select
          options={selectOptions}
          onChange={handleSelectChange}
          isMulti
          defaultValue={selectOptions}
          formatOptionLabel={formatOptionLabel}
          styles={customStyles}
        />
      </Box>
      <Box sx={{ flex: 1, border: '1px solid #ccc', borderRadius: '8px', p: 1 }}>
        <ReactECharts option={option} style={{ height: '100%', width: '100%' }} />
      </Box>
    </div>
  );
};

export default StandardsGraph;
