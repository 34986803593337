import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Drawer, Toolbar, Typography } from '@mui/material';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import MRTcustomizedOriginal from '../BaseLayout/MRTcustomizedOriginal'; // Adjust the import path as necessary
import MultiStepResourceForm from '../Risk/MultiStepResourceForm';

const DynamicSelectWidget = ({ onChange, schema, value, options }) => {
  const { getComplianceList, complianceList: choices } = useRequestCompliance({ endpoint: schema.endpoint });
  const [drawerOpen, setDrawerOpen] = useState(false);

  // List of endpoints where the "Create New" button should not be shown
  const hideCreateButtonEndpoints = ['processes', 'threat-event-instances', 'users', 'permissions', 'content-types', 'missions']; // Replace with actual endpoint names

  // Memoize the getComplianceList call with filters to avoid unnecessary calls
  const fetchComplianceList = useCallback(() => {
    // Only call getComplianceList when options or filters change
    if (options?.filters) {
      getComplianceList(options.filters);
    }
  }, [getComplianceList, options?.filters]);

  useEffect(() => {
    fetchComplianceList(); // Call the memoized function
  }, [fetchComplianceList]);

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleNewEntrySuccess = () => {
    fetchComplianceList(); // Refresh data with current filters after a new entry is created
    setDrawerOpen(false);
  };

  return (
    <div>
      <Box mb={2}>
        {!hideCreateButtonEndpoints.includes(schema.endpoint) && (
          <Typography variant="body1" gutterBottom>
            You can create a new entry or add from the table below.
          </Typography>
        )}
        {hideCreateButtonEndpoints.includes(schema.endpoint) && (
          <Typography variant="body1" gutterBottom>
            You can add from the table below.
          </Typography>
        )}
        {/* Conditionally render the "Create New" button */}
        {!hideCreateButtonEndpoints.includes(schema.endpoint) && (
          <Button color="primary" onClick={toggleDrawer}>
            Create New
          </Button>
        )}
        <MRTcustomizedOriginal
          key={schema?.title}
          results={choices.results}
          initialSelectedIds={value}
          enableRowSelection
          enableMultiRowSelection={schema.relationType === 'ManyToMany'}
          onSelectedIdsChange={onChange}
          hideActionsColumn={true}
        />
      </Box>
      <Drawer
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '70%',
          },
        }}
        anchor="right"
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Toolbar />
        <Box p={2}>
          <MultiStepResourceForm
            resourceName={schema.endpoint}
            id={null}
            onSubmitSuccess={handleNewEntrySuccess}
          />
        </Box>
      </Drawer>
    </div>
  );
};

export default DynamicSelectWidget;
